#sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #fff;
    z-index: 1045;
    transition: transform 0.3s ease-in-out;
}

#sidebar h4 {
    color: black;
    font-size: 15px;
}

#sidebar .divider {
    height: 1px;
    width: 100%;
    background-color: #F4F7FE;
}

#sidebar .nav-item {
    list-style-type: none; /* Remove the dot from the list item */
}

#sidebar .nav-item .nav-link {
    color: #182233 !important;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
    transition: opacity 0.3s ease-in-out;
    padding: 16px;
}

#sidebar .nav-item .logout {
    color: #FD4E5D !important;
    margin: 0px;
}

#sidebar .nav-item.active .nav-link {
    padding: 16px;
    border-radius: 16px;
    background-color: #F5FAFE;
}

#sidebar .environment {
    margin-top: 8px;
    font-size: 12px;
    margin-bottom: 0;
}

.deactivated {
    opacity: 0.3;
    cursor: default;
}

#sidebar .nav-item:not(.active):not(.deactivated):hover {
    opacity: 0.7;
}

#sidebar .nav.flex-column {
    flex-grow: 1;
}

.no-scroll {
    overflow: hidden;
}

.sidebar-category {
    font-size: 0.75rem;
    font-weight: 700;
    color: rgb(119, 131, 142);
    padding: 8px 16px;
    margin-top: 10px;
    margin-bottom: 5px;
    position: relative;
    text-transform: uppercase;
}

.sidebar-category .category-arrow {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: transform 0.3s ease-in-out;
}

.sidebar-category.expanded .category-arrow {
    transform: translateY(-50%) rotate(180deg);
}

.scrollable-content {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
}

.sidebar-footer {
    padding: 16px 0;
}

/* Full-screen sidebar styles for mobile */
@media (max-width: 992px) {
    #sidebar {
        width: calc(100% - 32px);
        top: 81px;
        left: 16px;
        transform: translateX(-105%);
        transition: transform 0.3s ease-in-out;
        z-index: 1045;
        background-color: white;
        height: calc(100 * var(--vh) - 101px);
        border-radius: 8px;
        padding: 16px;
        margin-top: 16px;
        user-select: none;
    }

    #sidebar.visible {
        transform: translateX(0);
    }

    #sidebar .nav-item .nav-link {
        border-radius: 0;
    }

    #sidebar .close-btn {
        display: block;
    }

    /* Aligning header content in one line on mobile */
    .sidebar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .sidebar-header img {
        width: 125px;
    }

    .title-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 8px;
    }

    .sidebar-title {
        font-size: 14px; /* Smaller font size for mobile */
    }

    .environment {
        margin-top: 8px;
        font-size: 10px; /* Smaller font size for environment text */
    }
}

/* Desktop styles */
@media (min-width: 992px) {
    #sidebar {
        position: fixed;
        top: 0;
        left: 0;
        height: calc(var(--vh, 1vh) * 100);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 250px; /* Fixed width */
        user-select: none;
    }

    /* Keep original styling for the sidebar header on larger screens */
    .sidebar-header {
        margin-top: 16px;
        margin-left: 16px;
    }

    .sidebar-header img {
        width: 150px;
    }

    .title-wrapper {
        display: block;
    }

    .sidebar-title {
        margin-top: 8px;
        font-size: 15px;
    }

    .environment {
        margin-top: 8px;
        font-size: 12px;
    }
}
