.switch {
    display: inline-flex;
    background-color: white;
    border-radius: 12px; 
    padding: 5px;
    user-select: none;
}
  
.switch-option {
    background: none;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 14px;
    transition: background-color 0.3s ease;
    color: black;
}

.switch-option:not(.active):hover {
    cursor: pointer !important;
}

.switch-option.active {
    background-color: #FCEDCB;
    color: #000;
}
