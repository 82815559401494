.payment-details {
    opacity: 0;
    animation: fadeInUp 1s forwards 0.5s;
}

.payment-details-card {
    border: 1px solid #dfe3ea;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
}

.payment-details .title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.payment-details .month-badge {
    background-color: #FCEDCB;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 14px;
    margin: 0;
}

.payment-details-card .number-item {
    color: black;
    padding: 10px;
    font-size: 22px;
    font-weight: bold;
}

.payment-details-card .number-item span {
    color: black;
    display: block;
    font-size: 14px;
    font-weight: normal;
}
