.page-container {
    min-height: calc(100 * var(--vh) - 81px);;
    background-color: #F5FAFE;
    padding: 32px;
}

.page-container-full {
    min-height: calc(var(--vh, 1vh) * 100);
    background-color: #F5FAFE;
}

#detail {
    padding: 0;
}

#content-area {
    overflow-y: auto;
    height: calc(100 * var(--vh) - 81px);;
}

.page-container .copyright {
    position: absolute;
    color: #75767b;
    font-size: 12px;
    text-align: center;
    bottom: 0;
}

.page-container .white-card,
.page-container-full .white-card {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
}

.page-container .white-card-with-border,
.page-container-full .white-card-with-border {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #dfe3ea;
}

.custom-input-field textarea {
    flex: 1;
}

@media (max-width: 992px) {
    .page-container {
        padding: 16px;
    }
}


@media (min-width: 992px) {
    #detail {
        margin-left: 250px;
        flex-grow: 1;
    }
}
