.expandable-section-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
}

.expandable-section-subtitle {
    font-size: 14px;
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 0;
}

.expandable-section-title-large {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
}

.expandable-section-subtitle-large {
    font-size: 16px;
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 0;
}

.disabled .expandable-section-title {
    color: grey;
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
}