.language-select {
    height: 50px;
    width: 90%;
    padding-left: 10px;
    padding-right: 10px;
    border: 0 !important;
    font-size: 15px;
    -webkit-tap-highlight-color: transparent !important;
}

.language-select:focus {
    border: 0 !important;
    box-shadow: none;
    outline: 0;
    -webkit-tap-highlight-color: transparent !important;
}
