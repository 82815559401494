/* AvailableUnitTable.css */
.available-unit-table-responsive {
    width: 100%;
    overflow-x: auto;
}

.available-unit-table {
    color: #333;
    width: 100%;
    border-collapse: collapse;
}

.available-unit-table thead {
    height: 50px;
}

.available-unit-table thead th {
    background-color: #FCFCFC;
    color: #222222;
    font-size: 15px;
    font-weight: bold;
    border: 0;
    vertical-align: middle;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
}

.available-unit-table tbody tr {
    height: 60px;
}

.available-unit-table tbody tr:hover {
    cursor: pointer;
    background-color: #FCFCFC !important;
}

.available-unit-table tbody tr.aktiv {
    background-color: #ffffff;
}

.available-unit-table tbody tr td {
    vertical-align: middle;
    color: #333333;
    font-size: 15px;
    border: 0;
    padding-left: 16px;
    padding-right: 16px;
}

.available-unit-table .square-feet-badge {
    background-color: #FCEDCB;
    color: black;
    border-radius: 0.625rem;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.available-unit-table .total-rent-amount-badge {
    background-color: #ffccc4;
    color: black;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.settings-icon:hover {
    cursor: pointer;
    opacity: 0.5;
}

@media (max-width: 992px) {
    .available-unit-table tbody tr {
        transition: none;
    }
    
    .available-unit-table tbody tr:hover {
        transform: none;
    }
}
