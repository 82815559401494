.sidebar-select-field {
    margin-bottom: 16px;
    position: relative;
}

.sidebar-select-field label {
    display: block;
    margin-bottom: 5px;
    color: #313135;
    font-size: 14px;
    font-weight: 600;
}

.sidebar-select-field .sidebar-select-wrapper {
    flex: 1; 
    position: relative;
    display: flex;
    align-items: center;
}

.sidebar-select-wrapper-on-top {
    display: flex;
    flex-direction: column;
}

.date-select .form-control {
    width: 100%;
    height: 45px;
    padding: 8px;
    border: 1px solid #EAEAEB;
    border-radius: 8px;
    font-size: 14px;
    background-color: white;
    color: #313135;
}

.date-select .form-control:focus {
    border: 1px solid #d3d1d1;
    outline: none;
}

.date-select .form-control:disabled {
    background-color: #f8f9fa;
    color: #6c757d;
}

.sidebar-select-field input {
    height: 45px;
    width: 100%;
    padding: 8px;
    padding-right: 30px;
    border: 1px solid #EAEAEB;
    border-radius: 8px;
    font-size: 14px;
    background-color: white;
}

.sidebar-select-field input:focus {
    border: 1px solid #d3d1d1;
    box-shadow: none;
    outline: 0;
}

.sidebar-select-field input::placeholder {
    color: #b4b2b2;
    opacity: 1;
}

/* Style for the error message */
.sidebar-select-field .error-message {
    color: #d9534f;
    font-size: 12px;
    font-weight: 400;
}

/* Custom DatePicker Styles */
.custom-datepicker-popper .react-datepicker {
    border: 1px solid #d3d1d1;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.custom-datepicker-popper .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
    padding-top: 10px;
}

.custom-datepicker-popper .react-datepicker__current-month {
    font-size: 16px;
    font-weight: 600;
    color: #313135;
}

.custom-datepicker-popper .react-datepicker__day-name {
    color: #999CA0;
    font-weight: 600;
    font-size: 12px;
}

.custom-datepicker-popper .react-datepicker__day {
    font-size: 14px;
    color: #313135;
    border-radius: 50%;
}

.custom-datepicker-popper .react-datepicker__day:hover {
    background-color: #f0f0f0;
}

.custom-datepicker-popper .react-datepicker__day--selected, 
.custom-datepicker-popper .react-datepicker__day--keyboard-selected {
    background-color: #FCEDCB;
    color: #000;
}

.custom-datepicker-popper .react-datepicker__day--today {
    font-weight: bold;
    color: #000;
}

.custom-datepicker-popper .react-datepicker__navigation {
    top: 15px;
    line-height: 1.7;
}

.custom-datepicker-popper .react-datepicker__navigation-icon::before {
    border-color: #313135;
}

.custom-datepicker-popper .react-datepicker__month-dropdown-container,
.custom-datepicker-popper .react-datepicker__year-dropdown-container {
    display: inline-block;
    margin-right: 10px;
}

.custom-datepicker-popper .react-datepicker__month-dropdown-container select,
.custom-datepicker-popper .react-datepicker__year-dropdown-container select {
    margin-top: 10px;
    background-color: #fff;
    border: 1px solid #d3d1d1;
    color: #313135;
    font-size: 12px;
    padding: 5px;
    border-radius: 8px;
}

.custom-datepicker-popper .react-datepicker__month-dropdown-container select:hover,
.custom-datepicker-popper .react-datepicker__month-dropdown-container select:focus,
.custom-datepicker-popper .react-datepicker__year-dropdown-container select:hover,
.custom-datepicker-popper .react-datepicker__year-dropdown-container select:focus {
    outline: none;
    border: 1px solid #313135;
}

.custom-datepicker-popper .react-datepicker__month-dropdown,
.custom-datepicker-popper .react-datepicker__year-dropdown {
    margin: 0;
    padding: 0;
}
