.unit-owner-details-card {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
    border: 1px solid #dfe3ea;
    opacity: 0;
    animation: fadeInLeft 1s forwards 0.5s;
}

.owner-details {
    transition: transform 0.3s ease;
}

.owner-details:hover {
    cursor: pointer;
    transform: scale(0.97);
}

.owner-details .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.owner-details .name {
    font-weight: 700;
    font-size: 1rem;
    margin: 0;
}

.owner-details .info {
    font-size: 0.875rem;
}

.owner-details .contact {
    display: flex;
    align-items: center;
    color: #000000;
    font-size: 0.875rem;
}

.owner-details .contact-icon {
    font-size: 1.2rem;
    color: #000000;
}

.owner-details .contact-link {
    text-decoration: none;
    color: #000000;
    transition: color 0.3s;
}

.owner-details .contact-link:hover {
    color: #0a0f0d;
}

.unit-owner-details-card .headline {
    font-weight: 600;
    margin: 20px 0;
    text-align: center;
    flex-grow: 1;
    font-size: 1rem;
}

.unit-owner-details-card .add-icon-container {
    font-size: 1.5rem;
    cursor: pointer;
}

.unit-owner-details-card img {
    height: 300px;
    width: 100%;
    object-fit: cover;
}

.unit-owner-details-card .divider {
    width: 100%;
    height: 1px;
    background-color: #F4F7FE;
    margin-bottom: 20px;
}
