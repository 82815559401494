.lease-payments {
    opacity: 0;
    animation: fadeInRight 1s forwards 0.5s;
}

.lease-payments .title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.lease-payments-table-card {
    border: 1px solid #dfe3ea;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
}

.lease-payments-table-responsive {
    width: 100%;
    overflow-y: auto;
    border-radius: 10px;
    border: 1px solid #dfe3ea;
}

.lease-payments-table-card .no-payments-text {
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: #666;
    margin-bottom: 0;
}

.lease-payments-table {
    color: #333;
    width: 100%;
    background-color: white;
}

.lease-payments-table thead {
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #FCFCFC;
}

.lease-payments-table thead th,
.lease-payments-table tbody td {
    text-align: center;
    vertical-align: middle;
    padding: 16px;
    font-size: 15px;
    color: #333;
}

.lease-payments-table tbody tr {
    height: 60px;
}

.lease-payments-table tbody tr:hover {
    cursor: pointer;
    background-color: #FCFCFC !important;
}

.lease-payments-table tbody tr.aktiv {
    background-color: #ffffff;
}

.lease-payments-table .status-badge-paid {
    background-color: #c6efd1;
    color: black;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.lease-payments-table .status-badge-unpaid {
    background-color: #efcfc6;
    color: black;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.lease-payments-table .status-badge-overdue {
    background-color: #9a4935;
    color: white;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 500;
}

.settings-icon {
    cursor: pointer;
}

.expandable-content {
    background-color: #f9f9f9;
}

.expanded-content-box {
    padding: 15px;
    text-align: start;
}

.lease-payments-table .remark {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 14px;
}

.lease-payments-table .cheque-number {
    display: block;
    font-size: 0.775rem;
    color: #666;
}

/* SKELETON LOADER */
.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    display: inline-block;
    animation: pulse 1.5s infinite;
}

.lease-payments .btn-create-payment {
    font-size: 14px;
    background-color: #FCEDCB;
    color: black;
    border: none;
    padding: 6px 12px;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.lease-payments .btn-create-payment:hover {
    background-color: rgb(239, 228, 201);
    cursor: pointer;
    transform: scale(0.95);
}
