/* Topbar.css */
.navbar .navbar-title {
    color: black;
    font-size: 16px;
}

.navbar {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    border-bottom: 1px solid rgba(28,28,28,0.1);
    background-color: white;
}

.notification-icon {
    width: 24px;
    height: 24px;
    color: black;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

/* Custom Search Input Styles */
.search-container {
    position: relative;
}

.search-input {
    background-color: #F3F3F3;
    color: #B1B5C1;
    font-size: 14px;
    padding-left: 40px;
    border: none;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.search-input:focus {
    background-color: #F3F3F3;
    color: #B1B5C1;
    border: 1px solid #d3d1d1;
    box-shadow: none;
    outline: 0;
}

.search-input::placeholder {
    color: #B1B5C1;
}

/* Adding the search icon */
.search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #B1B5C1;
    z-index: 10;
}
