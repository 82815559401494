.audit-logs-table-responsive {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.audit-logs-table {
    color: #333;
    width: 100%;
    border-collapse: collapse;
}

.audit-logs-table thead {
    height: 50px;
}

.audit-logs-table thead th {
    background-color: #FCFCFC;
    color: #222222;
    font-size: 15px;
    font-weight: bold;
    border: 0;
    vertical-align: middle;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
}

.audit-logs-table tbody tr {
    height: 60px;
}

.audit-logs-table tbody tr:hover {
    cursor: pointer;
    background-color: #FCFCFC !important;
}

.audit-logs-table tbody tr.aktiv {
    background-color: #ffffff;
}

.audit-logs-table tbody tr td {
    vertical-align: middle;
    color: #333333;
    font-size: 15px;
    border: 0;
    padding-left: 16px;
    padding-right: 16px;
}

.log-row {
    cursor: pointer;
}

.details-row {
    background-color: #f9f9f9;
    border-top: 1px solid #ddd;
}

.details-content {
    padding: 10px;
    font-size: 14px;
    color: #333;
}

.change-item {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #eee;
    margin-bottom: 5px;
}

.change-key {
    flex: 1;
    font-weight: bold;
    padding-right: 10px;
    color: #555;
}

.change-value {
    flex: 3;
    display: flex;
    align-items: center;
    font-size: 14px;
}

.old-value {
    color: #e74c3c;
    margin-right: 10px;
    display: inline-block;
    text-decoration: line-through;
}

.new-value {
    color: #27a85d;
    display: inline-block;
    margin-left: 10px;
}

.arrow {
    margin: 0 5px;
    color: #aaa;
}

.row-create {
    color: rgb(79, 147, 83) !important; /* Brighter green */
}

.row-delete {
    color: rgb(215, 55, 52) !important; /* More vivid red */
}

.row-update {
    color: rgb(27, 137, 188) !important; /* Sky blue */
}

@media (max-width: 992px) {
    .audit-logs-table tbody tr {
        transition: none;
    }
    
    .audit-logs-table tbody tr:hover {
        transform: none;
    }
}
