/* Rich text field container */
.rich-text-field {
    display: flex;
    flex-direction: column;
}

/* Label for the rich text editor */
.rich-text-field label {
    display: block;
    margin-bottom: 5px;
}

/* React Quill editor container */
.react-quill {
    height: auto; /* Adjust the height based on the content */
    min-height: 200px !important; /* Set a minimum height for the editor */
}

/* Styling for the Quill editor toolbar */
.ql-toolbar {
    border-top: 1px solid #EBEBEB !important;
    border-left: 1px solid #EBEBEB !important;
    border-right: 1px solid #EBEBEB !important;
    border-bottom: transparent !important;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

/* Styling for the Quill editor content container */
.ql-container {
    border: 1px solid #EBEBEB !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height: auto; /* Allow the container to grow with content */
    min-height: 100px; /* A minimum height for the content area */
}

/* Placeholder text style */
.ql-editor.ql-blank::before {
    font-size: 16px;
    color: #999;
}

/* Editor text style */
.ql-editor {
    font-size: 16px;
    min-height: 200px; /* Set minimum height for the content area */
}

/* Error message styling */
.text-danger {
    display: block; /* Ensure it takes its own line */
}
