.dashed-upload-container {
    border: 2px dashed #ccc;
    border-radius: 8px;
    cursor: pointer;
    color: #353535;
    text-align: center;
    width: 100%;
}

.upload-icon {
    width: 50px;
    height: 50px;
    background-color: #F5F5F5;
    border-radius: 50%;
    padding: 12px;
    display: inline-block;
    margin-bottom: 16px;
}

.file-upload-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}
.text-upload {
    font-size: 14px;
}

.text-upload-blue {
    color: #007bff;
    font-weight: 500;
}

.max-file-size {
    font-size: 12px;
}

/* Style adjustments for hover state */
.dashed-upload-container:hover {
    background-color: #f8f9fa;
}

.cursor-pointer {
    cursor: pointer;
}
