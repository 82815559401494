.payments {
    opacity: 0;
    animation: fadeInUp 1s forwards 0.5s;
}

.payments .title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.payments-table-card {
    border: 1px solid #dfe3ea;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
}

.payments-table-responsive {
    width: 100%;
    max-height: 360px;
    overflow-x: auto;
    border-radius: 10px;
    border: 1px solid #dfe3ea;
}

.payments-table-card .no-payments-text {
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: #666;
    margin-bottom: 0;
}

.payments-table-wrapper {
    display: flex;
    flex-direction: column;
}

.payments-table-body {
    max-height: 360px;
    overflow-y: scroll;
    border: 1px solid #dfe3ea;
    border-radius: 10px;
}

.payments-table {
    color: #333;
    width: 100%;
    background-color: white;
}

.payments-table thead {
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #FCFCFC;
}

.payments-table thead th,
.payments-table tbody td {
    text-align: center;
    vertical-align: middle;
    padding: 16px;
    font-size: 15px;
    color: #333;
}

.payments-table tbody tr {
    height: 60px;
}

.payments-table tbody tr:hover {
    cursor: pointer;
    background-color: #FCFCFC !important;
}

.payments-table tbody tr.aktiv {
    background-color: #ffffff;
}

.payments-table .total-amount-badge {
    background-color: #efcfc6;
    color: black;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.payments-table .cheque-number {
    display: block;
    font-size: 0.775rem;
    color: #666;
}


/* SKELETON LOADER */
.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    display: inline-block;
    animation: pulse 1.5s infinite;
}

.skeleton.due-date {
    width: 120px;
    height: 20px;
}

.skeleton.amount {
    width: 100px;
    height: 20px;
}

.skeleton.method,
.skeleton.purpose {
    width: 140px;
    height: 20px;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f5f5f5;
    }
    100% {
        background-color: #e0e0e0;
    }
}
