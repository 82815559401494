.sidebar-input-field {
    margin-bottom: 16px;
    position: relative;
}

.sidebar-input-field label {
    display: block;
    margin-bottom: 5px;
    color: #313135;
    font-size: 14px;
    font-weight: 600;
}

.sidebar-input-field .sidebar-input-wrapper {
    flex: 1; 
    position: relative;
    display: flex;
    align-items: center;
}

.sidebar-input-field input,
.sidebar-input-field textarea {
    width: 100%;
    padding: 8px;
    padding-right: 30px;
    border: 1px solid #EAEAEB;
    border-radius: 8px;
    font-size: 14px;
    background-color: white;
}

.sidebar-input-field input::placeholder,
.sidebar-input-field textarea::placeholder {
    color: #b4b2b2;
    opacity: 1;
}

.sidebar-input-field input {
    height: 45px;
}

.sidebar-input-field textarea {
    height: 80px;
    resize: vertical;
}

.sidebar-input-field input:focus,
.sidebar-input-field textarea:focus {
    border: 1px solid #d3d1d1;
    box-shadow: none;
    outline: 0;
}

.sidebar-input-field input:focus ~ .clear-icon,
.sidebar-input-field textarea:focus ~ .clear-icon {
    color: #999CA0;
}

.sidebar-input-field .clear-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #999CA0;
}

.sidebar-input-field input:focus + .clear-icon,
.sidebar-input-field textarea:focus + .clear-icon {
    color: #999CA0;
}

/* Style for the error message */
.sidebar-input-field .error-message {
    color: #d9534f;
    font-size: 12px;
    font-weight: 400;
}

.sidebar-input-field .icon-container {
    display: flex;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    height: 100%;
}

.sidebar-input-field .icon {
    margin-left: 8px;
    cursor: pointer;
    color: #999CA0;
    font-size: 18px;
}
