.show-picture-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1045;
}

.show-picture-modal-content {
    text-align: center;
    max-width: 80%;
    max-height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px;
}

.show-picture-modal-image-wrapper {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 20px 0;
}

.show-picture-close-icon {
    position: absolute;
    top: 50px;
    right: 10px;
    cursor: pointer;
    font-size: 40px;
    color: white;
    z-index: 1050;
}

.show-picture-nav-button {
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    transition: background-color 0.15s ease;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.show-picture-nav-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.carousel-control-prev {
    left: -60px;
}

.carousel-control-next {
    right: -60px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    background-size: 100%, 100%;
    width: 1.5rem;
    height: 1.5rem;
}

.show-picture-modal-image {
    max-width: 100%;
    max-height: calc(100vh - 80px);
    border-radius: 16px;
    object-fit: contain;
}

@media (max-width: 767.98px) {
    .show-picture-modal-content {
        max-width: 100%;
        max-height: 100%;
    }

    .show-picture-nav-button {
        font-size: 24px;
        width: 36px;
        height: 36px;
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        width: 1rem;
        height: 1rem;
    }
}
