.unit-detail-page .headline {
    font-weight: 600;
}

.unit-detail-avatar {
    width: 150px;
    height: 150px;
    border-radius: 75px;
}


.pm-contract-sign-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    text-align: center;
}

.pm-contract-sign-message {
    margin-bottom: 20px;
    font-size: 1rem;
}

.form-check.form-switch {
    align-items: center;
}

@media (max-width: 767.98px) { 
    .unit-detail-avatar {
        width: 80px;
        height: 80px;
        border-radius: 40px;
    }
}
