/* unit-pictures-section.module.css */

.imageSquare {
    position: relative;
    width: 100%;
    padding-top: 100%;
    overflow: hidden;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

/* Image styles */
.imageSquare img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.removeIcon {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    color: white;
}
