.paymentTableContainer {
    width: 100%;
    overflow-x: auto;
}

.paymentTable {
    color: #333;
    width: 100%;
    border-collapse: collapse;
}

.paymentTable thead {
    height: 50px;
}

.paymentTable thead th {
    background-color: #FCFCFC;
    color: #222222;
    font-size: 15px;
    font-weight: bold;
    border: 0;
    vertical-align: middle;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
}

.paymentTable tbody tr {
    height: 60px;
}

.paymentTable tbody tr:hover {
    cursor: pointer;
    background-color: white !important;
}

.paymentTable tbody tr.aktiv {
    background-color: #ffffff;
}

.paymentTable tbody tr.entwurf {
    background-color: #FFFCF5;
}

.paymentTable tbody tr.inaktiv {
    background-color: #FFF6F7;
}

.paymentTable tbody tr td {
    vertical-align: middle;
    color: #333333;
    font-size: 15px;
    border: 0;
    padding-left: 16px;
    padding-right: 16px;
}

.totalAmountBadge {
    background-color: #FCEDCB;
    color: black;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

@media (max-width: 992px) {
    .paymentTableContainer {
        overflow-x: auto;
    }

    .paymentTable {
        min-width: 600px;
    }
}
