/* UnitDetails.css */
.unit-details-card {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    overflow: hidden;
    border: 1px solid #dfe3ea;
    opacity: 0;
    animation: fadeInLeft 1s forwards 0.5s;
}

.unit-details-card .image-container {
    position: relative;
    width: 100%;
}

.unit-details-card img {
    height: 300px;
    width: 100%;
    object-fit: cover;
}

.unit-details-card .badge {
    position: absolute;
    bottom: 16px;
    left: 16px;
    background-color: #FCEDCB;
    color: black;
    padding: 8px 10px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: normal;
}

.unit-details-card .unit-info {
    text-align: center;
}

.unit-details-card .unit-name {
    font-weight: bold;
    margin-top: 20px;
}

.unit-details-card .property-name {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 20px;
}

.unit-details-card .divider {
    width: 100%;
    height: 1px;
    background-color: #F4F7FE;
    margin-bottom: 20px;
}

.unit-details-card .headline {
    font-weight: 600;
    margin: 20px 0;
    text-align: center;
    flex-grow: 1;
    font-size: 1.2rem;
}

.unit-details-card .stats {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    margin-bottom: 15px;
}

.unit-details-card .stat-item {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: black;
}

.unit-details-card .stat-item span {
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #6c757d;
}
