.paymentDetailsCard {
    border: 1px solid #dfe3ea;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    opacity: 0;
    animation: fadeInUp 1s forwards 0.5s;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
}

.paymentDetailsCard:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.numberItem {
    color: black;
    font-size: 22px;
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
}

.numberItem span {
    color: black;
    display: block;
    font-size: 14px;
    font-weight: normal;
}

/* Keyframe animations */
@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
