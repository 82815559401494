/* TenantTable.css */
.tenant-table-responsive {
    width: 100%;
    overflow-x: auto;
}

.tenant-table {
    color: #333;
    width: 100%;
    border-collapse: collapse;
}

.tenant-table thead {
    height: 50px;
}

.tenant-table thead th {
    background-color: #FCFCFC;
    color: #222222;
    font-size: 15px;
    font-weight: bold;
    border: 0;
    vertical-align: middle;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
}

.tenant-table tbody tr {
    height: 60px;
}

.tenant-table tbody tr:hover {
    cursor: pointer;
    background-color: #FCFCFC !important;
}

.tenant-table tbody tr.aktiv {
    background-color: #ffffff;
}

.tenant-table tbody tr td {
    vertical-align: middle;
    color: #333333;
    font-size: 15px;
    border: 0;
    padding-left: 16px;
    padding-right: 16px;
}

.email-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.email-action {
    color: #6B7280;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.email-action:hover {
    cursor: pointer;
    opacity: 0.5;
}

.email-action span {
    margin-right: 4px;
}

.settings-icon:hover {
    cursor: pointer;
    opacity: 0.5;
}

.tenant-table .status-badge-active {
    background-color: #c6efd1;
    color: black;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.tenant-table .status-badge-inactive {
    background-color: #efcfc6;
    color: black;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

@media (max-width: 992px) {
    .tenant-table tbody tr {
        transition: none;
    }

    .tenant-table tbody tr:hover {
        transform: none;
    }
}
