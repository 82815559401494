/* TableComponent.css */
.employee-table {
    color: #333;
    width: 100%;
    border-collapse: collapse;
}

.employee-table thead {
    height: 50px;
}

.employee-table thead th {
    background-color: #FCFCFC;
    color: #222222;
    font-size: 15px;
    font-weight: bold;
    border: 0;
    vertical-align: middle;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
}

.employee-table tbody tr {
    height: 60px;
}

.employee-table tbody tr:hover {
    cursor: pointer;
    background-color: #FCFCFC !important;
}

.employee-table tbody tr.aktiv {
    background-color: #ffffff;
}

.employee-table tbody tr td {
    vertical-align: middle;
    color: #333333;
    font-size: 15px;
    border: 0;
    padding-left: 16px;
    padding-right: 16px;
}

.email-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.email-action {
    color: #6B7280;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.email-action:hover {
    cursor: pointer;
    opacity: 0.5;
}

.email-action span {
    margin-right: 4px;
}

.sales-agent img {
    width: 30px; /* Adjust size as needed */
    border-radius: 50%;
    margin-right: 5px;
}

.settings-icon:hover {
    cursor: pointer;
    opacity: 0.5;
}


@media (max-width: 992px) {
    .employee-table tbody tr {
        transition: none;
    }
    
    .employee-table tbody tr:hover {
        transform: none;
    }
}
