.newsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.newsItem:hover {
    cursor: pointer;
}

.newsItem.highlighted {
    border: 2px dotted #82be93;
}

.newsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    position: relative;
}

.newsHeader h2 {
    font-size: 1.2rem;
    margin: 0;
    flex-grow: 1;
}

.newsContent {
    margin-bottom: 0;
    margin-top: 16px;
    font-size: 1rem;
}

.newsContent p {
    margin: 0;
}

.newsFooter {
    text-align: right;
    font-size: 0.875rem;
    color: #888;
}

.newsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.deleteIcon {
    color: #ff6347;
    cursor: pointer;
}

.deleteIcon:hover {
    color: #d9534f;
}
