.sidebar-select-field {
    margin-bottom: 16px;
    position: relative;
}

.sidebar-select-field label {
    display: block;
    margin-bottom: 5px;
    color: #313135;
    font-size: 14px;
    font-weight: 600;
}

.sidebar-select-field .select-wrapper {
    width: 100%;
}

.sidebar-select-field .sidebar-input-wrapper {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
}

.sidebar-select-field .form-select::placeholder {
    color: #b4b2b2;
    opacity: 1;
}

/* Hide the default arrow in select */
.sidebar-select-field .form-select {
    appearance: none; 
    -webkit-appearance: none; /* Safari */
    -moz-appearance: none; /* Firefox */
    height: 45px;
    width: 100%;
    padding: 8px;
    padding-right: 30px;
    border: 1px solid #EAEAEB;
    border-radius: 8px;
    font-size: 14px;
    background-color: white;
    background-image: none;
    color: #313135;
}

/* Add this rule to style the select when the placeholder is active */
.sidebar-select-field .form-select.placeholder {
    color: #b4b2b2;
    opacity: 1;
}

.sidebar-select-field .form-select:disabled {
    background-color: #f8f9fa;
    color: #EAEAEB !important;
}

.sidebar-select-field .form-select:focus {
    border: 1px solid #d3d1d1;
    box-shadow: none;
    outline: 0;
}

.sidebar-select-field .icon-container {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #999CA0;
    font-size: 18px;
    display: flex;
    align-items: center;
    height: 100%;
}

.sidebar-select-field .dropdown-arrow-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #999CA0;
    font-size: 18px;
    display: flex;
    align-items: center;
    height: 100%;
    transition: right 0.3s;
}

.sidebar-select-field .icon-container:hover {
    color: #dc3545;
}

.sidebar-select-field .error-message {
    color: #d9534f;
    font-size: 12px;
    font-weight: 400;
}

/* Dropdown menu styling */
.sidebar-select-field .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    display: block;
    float: left;
    max-height: 200px;
    overflow-y: auto;
    min-width: 100%;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
}

.sidebar-select-field .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
}

.sidebar-select-field .empty-search {
    font-size: 14px;
    color: #8a8a8a;
}

.sidebar-select-field .form-select::placeholder {
    color: #b4b2b2 !important;
    opacity: 1 !important;
}
