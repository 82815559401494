/* BarChart.css */
.chart-container {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    background-color: #fff;
    padding: 20px;
    font-family: 'Arial';
  }
  
  .chart-title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 16px;
  }
  
  /* You may need to adjust the styles below depending on the chart library's specific classes */
  .chartjs-render-monitor {
    background-color: #f5f5f5;
    border-radius: 16px;
  }
  
  /* Customize axis and grid lines */
  .Chartjs-2-axis-line {
    stroke: #e8e8e8;
  }
  
  .Chartjs-2-grid-line {
    stroke: #e8e8e8;
  }
  
  /* Customize ticks */
  .Chartjs-2-tick {
    fill: #333;
    font-size: 12px;
  }
  
  /* Customize tooltip */
  .Chartjs-2-tooltip {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: 8px;
    font-size: 12px;
  }
  
  /* Customize legend */
  .Chartjs-2-legend {
    color: #333;
    font-size: 14px;
  }
  