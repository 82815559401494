.file-status {
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #CACACA;
}

.file-status-name {
    color: #333333;
    font-size: 15px;
}

.file-status-download {
    color: #007bff;
    font-size: 14px;
}

.file-status-download:hover {
    cursor: pointer;
}

.file-status-size {
    color: #989692;
    font-size: 13px;
}

.file-status-indicator {
    color: #353535;
    font-size: 13px;
}

.progress {
    height: 10px;
    border-radius: 5px;
}

.file-status-success {
    background-color: #50C878;
}

.file-status-progress {
    background-color: #4A74EA;
}

.file-remove-icon {
    color: #353535;
    transition: transform 0.5s ease;
}

.file-remove-icon:hover {
    cursor: pointer;
    transform: scale(0.90);
}

.valid-until-badge {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: white;
    margin-left: 10px;
}

.valid-until-badge.valid {
    background-color: #27ae60;
}

.valid-until-badge.expiring-soon {
    background-color: #e67e22;
}

.valid-until-badge.expired {
    background-color: #c0392b;
}

.badge-status-text {
    margin-right: 8px;
}

.badge-date {
    font-weight: 400;
    font-size: 11px;
    color: #ffffffcc;
}

/* Add badge for internal documents */
.internal-document-badge {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background-color: #f39c12; /* Orange color for internal document */
    color: white;
    font-size: 12px;
    border-radius: 12px;
    font-weight: bold;
    margin-left: 10px;
}

.internal-document-icon {
    margin-right: 5px;
    font-size: 18px;
}

.internal-document-text {
    text-transform: uppercase;
}

/* Tooltip styling */
.tooltip-inner {
    max-width: 200px;
    font-size: 12px;
    text-align: center;
    padding: 5px 10px;
    background-color: #333;
    color: white;
}
