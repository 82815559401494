.leaseTenantDetails {
    opacity: 0;
    animation: fadeInUp 1s forwards 0.5s;
}

.leaseTenantDetails .title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.btnCreatePayment {
    font-size: 14px;
    background-color: #FCEDCB;
    color: black;
    border: none;
    padding: 6px 12px;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.leaseTenantDetailsCard {
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
    border: 1px solid #dfe3ea;
    transition: transform 0.3s ease;
    display: flex;
    padding: 10px;
    cursor: pointer;
}

.leaseTenantDetailsCard:hover {
    transform: scale(0.97);
}

.tenant {
    display: flex;
    align-items: center;
    margin-left: 8px;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.tenantInfo {
    margin-left: 15px;
    flex-grow: 1;
}

.tenantHeader {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.name {
    font-weight: 700;
    font-size: 1rem;
    margin: 0;
}

.info {
    font-size: 0.875rem;
    margin: 0;
}

.contactLink {
    text-decoration: none;
    color: #19248a;
    transition: color 0.3s;
    margin-left: 10px;
}

.contactLink:hover {
    color: #0a0f0d;
}

.contactIcon {
    font-size: 1.2rem;
}

/* Keyframe animations */
@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
