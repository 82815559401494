.parking-space-section {
    margin-bottom: 16px;
}

.parking-space-section h5 {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    color: #313135;
}

.parking-space-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 16px;
}

.parking-chip {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    background-color: #313135;
    color: white;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.parking-chip .delete-icon {
    margin-left: 8px;
    cursor: pointer;
    font-size: 16px;
    color: white;
}

.add-chip {
    display: inline-flex;
    align-items: center;
    padding: 8px 12px;
    color: #313135;
    border: 2px dashed #ccc;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

.add-chip input {
    border: none;
    background: transparent;
    outline: none;
    color: #313135;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 500;
}

.add-chip input::placeholder {
    color: #313135;
}

.add-chip .enter-to-save {
    margin-left: 10px;
    font-size: 12px;
    color: #999;
}

.parking-chip:hover {
    background-color: #007bff;
    color: #fff;
}

.parking-chip:hover .delete-icon {
    color: #fff;
}

.loading-spinner {
    animation: spin 1s linear infinite;
    margin-right: 8px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}