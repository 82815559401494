/* Backdrop */
.sidebar-modal-backdrop {
    position: fixed;
    top: 0 !important;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1045;
    margin-top: 0 !important;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.sidebar-modal-backdrop.visible {
    opacity: 1;
    visibility: visible;
}

/* Sidebar Modal */
.sidebar-modal {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    background: #FFF;
    z-index: 1050;
    box-shadow: -1px 0 8px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out, visibility 0.3s ease;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    user-select: none;
    max-width: 100vw;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    visibility: hidden;
}

.sidebar-modal.visible {
    right: 0;
    visibility: visible;
}

/* Modal Headline */
.sidebar-modal-headline {
    color: black;
    font-size: 18px;
    margin: 0;
}

/* Divider */
.sidebar-modal-divider {
    height: 1px;
    background-color: #F4F7FE;
}

/* Scrollable Content */
.sidebar-modal-scrollable-content {
    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    padding: 20px;
    padding-bottom: 60px;
    max-height: calc(100vh - 8rem);
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
}

/* Button Container */
.sidebar-modal .store-investor-btn {
    padding: 20px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    box-sizing: border-box;
    border-top: 1px solid #ddd;
}

/* Mobile styles (for smaller screens) */
@media (max-width: 767px) {
    .sidebar-modal {
        width: 100%;
        border-radius: 0;
    }

    .sidebar-modal.visible {
        right: 0;
    }

    .sidebar-modal-scrollable-content {
        max-height: calc(100vh - 100px);
    }

    .store-investor-btn {
        padding: 10px;
    }
}

/* Desktop styles */
@media (min-width: 768px) {
    .sidebar-modal {
        width: 35%;
    }
}

.sidebar-modal-backdrop,
.sidebar-modal {
    transition: opacity 0.3s ease, visibility 0.3s ease, right 0.3s ease-in-out;
}
