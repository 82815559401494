.custom-alert {
    background-color: #fff; /* Light background */
    border-left: 5px solid #007bff; /* Default border color */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    color: #333; /* Dark text for contrast */
    padding: 15px; /* Spacing inside the alert */
    margin-bottom: 20px; /* Spacing outside the alert */
    position: sticky;
    top: 0; /* Stick to the top of the viewport */
    z-index: 1000; /* High z-index to ensure it's above other content */
  }
  
  /* Styles for different alert types */
  .alert-warning {
    border-left-color: #ffc107; /* Yellow for warning */
    /* Optional: add background-color or text color changes */
  }
  
  .alert-error {
    border-left-color: #dc3545; /* Red for error */
    /* Optional: add background-color or text color changes */
  }
  
  .alert-success {
    border-left-color: #28a745; /* Green for success */
    /* Optional: add background-color or text color changes */
  }
  
  .alert-info {
    border-left-color: #17a2b8; /* Blue for info */
    /* Optional: add background-color or text color changes */
  }
  
  .custom-alert strong {
    margin-right: 10px; /* Space between strong tag and text */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
  }
  
  .close-button:hover {
    color: #0056b3; /* Color change on hover */
  }  