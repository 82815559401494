.PhoneInputInput {
    border: 0 !important;
    margin-right: 20px !important;
}

.PhoneInputInput:focus {
    outline: none;
    box-shadow: none;
}

.phone-input-field {
    margin-bottom: 16px;
    position: relative;
}

.phone-input-field label {
    display: block;
    margin-bottom: 5px;
    color: #313135;
    font-size: 14px;
    font-weight: 600;
}

.phone-input-field .input-wrapper {
    height: 45px;
    flex: 1; 
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #EAEAEB;
    border-radius: 8px;
    background-color: white;
}

.phone-input-field .phone-input {
    flex-grow: 1;
    font-size: 14px;
    padding: 8px;
    border: none;
    box-sizing: border-box;
}

.phone-input-field input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    background-color: white;
}

.phone-input-field .phone-input:focus {
    outline: none;
    box-shadow: none;
    border-color: #d3d1d1;
}

.phone-input-field .error-message {
    color: #d9534f;
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}

.phone-input-field .clear-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #999CA0;
    background-color: transparent;
    border: none;
}

.phone-input-field input:focus ~ .clear-icon {
    color: #999CA0;
}

.phone-input-field .phone-input:focus ~ .clear-icon {
    color: #999CA0;
}

.phone-input-field .input-wrapper .PhoneInputWithCountrySelect,
.phone-input-field .input-wrapper .PhoneInputWithCountrySelect *,
.phone-input-field .input-wrapper .PhoneInputWithCountrySelect .phone-number-input--flag {
    height: 100%;
    align-items: center;
    border: none;
    background-color: transparent;
}

.phone-input-field .input-wrapper.disabled {
    background-color: #f8f9fa;
    color: #6c757d;
}

#phone-number:disabled {
    background-color: #f8f9fa;
    color: #6c757d;
}
