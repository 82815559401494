/* ScrollToTopButton.css */
.scroll-to-top-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.scroll-to-top-button.visible {
    opacity: 1;
    visibility: visible;
}

.scroll-to-top-button:hover {
    background-color: #0056b3;
}

.scroll-to-top-button:focus {
    outline: none;
}
