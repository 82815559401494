.date-of-birth-select {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.date-of-birth-select select {
    width: 30%;
    height: 45px;
    padding: 8px;
    border: 1px solid #EAEAEB;
    border-radius: 8px;
    font-size: 14px;
    background-color: white;
    color: #313135;
}

.date-of-birth-select select:focus {
    border: 1px solid #d3d1d1;
    outline: none;
}

.date-of-birth-select select:disabled {
    background-color: #f8f9fa;
    color: #6c757d;
}

/* Style for the error message */
.sidebar-select-field .error-message {
    color: #d9534f;
    font-size: 12px;
    font-weight: 400;
}
