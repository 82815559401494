.round-image-container {
    position: relative;
    width: 125px;
    height: 125px;
    margin: 0 auto;
}

.round-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.pencil-icon {
    height: 25px;
    width: 25px;
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: rgb(78, 43, 204);
    border-radius: 50%;
    cursor: pointer;
    color: white;
}
