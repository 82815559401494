.unit-status-badge {
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.unit-status-badge.available {
    background-color: #cff4fc;
    color: #007bff;
}

.unit-status-badge.rented {
    background-color: #fff3cd;
    color: #856404;
}

.unit-status-badge.for-sale {
    background-color: #f8d7da;
    color: #721c24;
}
