/* ConstructionUpdateCard.css */

.construction-update-card {
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease;
    opacity: 0;
}

.construction-update-card:hover {
    cursor: pointer;
    transform: scale(0.99);
}

.construction-update-image {
    width: 100%;
    height: 200px;
    display: block;
    object-fit: cover;
    opacity: 0;
    transition: opacity 0.5s ease;
}

.start-fade-animation .construction-update-image {
    opacity: 1;
}

.construction-update-content {
    padding: 16px;
}

.construction-update-title {
    margin-top: 8px;
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.construction-update-description {
    font-size: 14px;
    color: #6B7280;
    margin-top: 4px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.construction-update-date {
    font-size: 14px;
    color: #6B7280;
    margin-top: 4px;
    margin-bottom: 0;
}

.construction-update-image-wrapper {
    position: relative;
}

.construction-update-status-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    z-index: 3;
}

.construction-update-status-badge.published {
    background-color: #2ecc71;
}

.construction-update-status-badge.not-published {
    background-color: #c0392b;
}
