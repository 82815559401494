.available-units {
    opacity: 0;
    animation: fadeInUp 1s forwards 0.5s;
}

.available-units-card {
    border: 1px solid #dfe3ea;
    border-radius: 10px;
    padding: 20px;
    background-color: white;
}

.available-units .title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.available-units-card .no-units-text {
    text-align: center;
    width: 100%;
    font-size: 14px;
    color: #666;
    margin-bottom: 0;
}

.available-units-table-responsive {
    width: 100%;
    overflow-x: auto;
    border-radius: 10px;
    border: 1px solid #dfe3ea;
}

.available-units-table {
    color: #333;
    width: 100%;
    background-color: white;
}

.available-units-table thead {
    height: 50px;
}

.available-units-table thead th,
.available-units-table tbody td {
    text-align: center;
    vertical-align: middle;
    padding: 16px;
    font-size: 15px;
    color: #333;
}

.available-units-table thead th {
    background-color: #FCFCFC;
    font-weight: bold;
}

.available-units-table tbody tr {
    height: 60px;
}

.available-units-table tbody tr:hover {
    cursor: pointer;
    background-color: #FCFCFC !important;
}

.available-units-table tbody tr.aktiv {
    background-color: #ffffff;
}

.available-units-table .total-rent-amount-badge {
    background-color: #FCEDCB;
    color: black;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.available-units-table .deposit-amount-badge {
    background-color: #f9d4d4;
    color: black;
    border-radius: 10px;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.available-units .btn-view-all {
    font-size: 14px;
    background-color: #FCEDCB;
    color: black;
    border: none;
    padding: 6px 12px;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.available-units .btn-view-all:hover {
    background-color: rgb(239, 228, 201);
    cursor: pointer;
    transform: scale(0.95);
}

@media (max-width: 992px) {
    .available-units-table tbody tr {
        transition: none;
    }

    .available-units-table tbody tr:hover {
        transform: none;
    }
}

/* SKELETON LOADER */
.skeleton {
    background-color: #e0e0e0;
    border-radius: 4px;
    display: inline-block;
    animation: pulse 1.5s infinite;
}

.skeleton.unit-id {
    width: 70px;
    height: 20px;
}

.skeleton.bedroom,
.skeleton.bathroom {
    width: 30px;
    height: 20px;
}

.skeleton.rent {
    width: 100px;
    height: 20px;
}

@keyframes pulse {
    0% {
        background-color: #e0e0e0;
    }
    50% {
        background-color: #f5f5f5;
    }
    100% {
        background-color: #e0e0e0;
    }
}
