.unitsOwnedContainer {
    opacity: 0;
    animation: fadeInUp 1s forwards;
    transition: transform 0.3s ease;
}

.ownedUnitsTableResponsive {
    width: 100%;
    overflow-x: auto;
}

.ownedUnitsTable {
    color: #333;
    width: 100%;
    border-collapse: collapse;
    min-width: 37.5rem;
}

.ownedUnitsTable thead {
    height: 3.125rem;
}

.ownedUnitsTable thead th {
    background-color: #FCFCFC;
    color: #222222;
    font-size: 0.9375rem;
    font-weight: bold;
    border: 0;
    vertical-align: middle;
    height: 100%;
    padding-left: 1rem;
}

.ownedUnitsTable tbody tr {
    height: 3.75rem;
}

.ownedUnitsTable tbody tr:hover {
    cursor: pointer;
    background-color: #F0F0F0 !important;
}

.ownedUnitsTable tbody tr td {
    vertical-align: middle;
    color: #333333;
    font-size: 0.9375rem;
    border: 0;
    padding-left: 1rem;
    padding-right: 1rem;
}

.ownedUnitsTable .squareFeetBadge {
    background-color: #FCEDCB;
    color: black;
    border-radius: 0.625rem;
    padding: 0.25rem 0.75rem;
    font-size: 0.875rem;
}

.addUnitButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    transition: transform 0.5s ease;
}

.addUnitButton:hover {
    font-weight: 600;
    transform: scale(0.98);
}

.addUnitButtonIcon {
    font-size: 24px;
    margin-right: 8px;
}

.emptyMessage {
    margin: 0;
    font-size: 0.875rem;
}

@media (max-width: 992px) {
    .ownedUnitsTable tbody tr {
        transition: none;
    }
    
    .ownedUnitsTable tbody tr:hover {
        transform: none;
    }
}

/* Keyframe animations */
@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
