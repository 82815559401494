/* Quote.css */

.quote-text {
    font-size: 36px;
    font-weight: 400;
    color: #333;
    margin-bottom: 0;
}

.quote-author {
    margin-top: 0;
    font-size: 18px;
    color: #3A424A;
    text-align: end;
}

.bold {
    font-weight: bold;
    color: #3062D4;
}

@media (max-width: 1199.98px) { 
    .quote-text {
        font-size: 24px;
    }
    
    .quote-author {
        font-size: 12px;
    }
}