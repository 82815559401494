.bank-detail-section {
    opacity: 0;
    animation: fadeInUp 1s forwards;
    transition: transform 0.3s ease;
}

.bank-detail-section .delete-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    transition: transform 0.5s ease;
}

.bank-detail-section .delete-button:hover {
    font-weight: 600;
    transform: scale(0.98);
}

.bank-detail-section .delete-button-icon {
    font-size: 24px;
    margin-right: 8px;
}