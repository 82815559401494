.investor-detail-subtitle {
    font-size: 16px;
    font-weight: 600;
}

.investor-address-section {
    opacity: 0;
    animation: fadeInUp 1s forwards;
    transition: transform 0.3s ease;
}
