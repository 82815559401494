.custom-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.custom-btn:disabled {
    opacity: 0.3;
}

/* Regular button */
.btn-regular {
    height: 50px;
    padding: 10px 20px;
}

/* Small button */
.btn-small {
    height: 40px;
    padding: 5px 10px;
    font-size: 14px;
}

.custom-btn:hover {
    transform: scale(0.97);
}

.custom-btn-icon {
    width: 20px;
    height: 20px;
}
