/* Styles for the image container */
.image-square {
  position: relative;
  width: 100%;
  padding-top: 100%;
  transition: transform 0.3s ease;
}

/* Image styles */
.image-square img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;  
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

  /*.image-square:hover {
    cursor: pointer;
    transform: scale(0.95);
  }*/
  
  .remove-icon {
    height: 25px;
    width: 25px;
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    color: white;
  }

  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
    position: relative;
    height: 0;
    border-radius: 8px;
  }
  
  .video-responsive video {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  