.input-field {
    margin-bottom: 16px;
    position: relative;
}

.input-field label {
    display: block;
    margin-bottom: 5px;
    color: #313135;
    font-size: 14px;
    font-weight: 600;
}

.input-field .input-wrapper {
    flex: 1; 
    position: relative;
    display: flex;
    align-items: center;
}

.input-field input {
    height: 45px;
    width: 100%;
    padding: 8px;
    padding-right: 30px;
    border: 1px solid #EAEAEB;
    border-radius: 8px;
    font-size: 14px;
    background-color: white;
}

.input-field input:focus {
    border: 1px solid #d3d1d1;
    box-shadow: none;
    outline: 0;
}

.input-field textarea {
    min-height: 150px;
    width: 100%;
    padding: 8px;
    padding-right: 30px;
    border: 1px solid #EBEBEB;
    border-radius: 8px;
    font-size: 16px;
    resize: none;
    overflow: auto;
}
  
.input-field textarea:focus {
    border: 1px solid #d3d1d1;
    box-shadow: none;
    outline: 0;
}

.input-field input::placeholder,
.input-field textarea::placeholder {
    color: #b4b2b2;
    opacity: 1;
}

.input-field .icon-container {
    display: flex;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    align-items: center;
    height: 100%;
}

.input-field .icon {
    margin-left: 8px;
    cursor: pointer;
    color: #999CA0;
    font-size: 18px;
}

.input-field .icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
