.occupantDetails {
    opacity: 0;
    animation: fadeInUp 1s forwards 0.3s;
}

.occupantDetails .title {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
}

.btnCreateOccupant {
    font-size: 14px;
    background-color: #FCEDCB;
    color: black;
    border: none;
    padding: 6px 12px;
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.btnCreateOccupant:hover {
    background-color: rgb(239, 228, 201);
    cursor: pointer;
    transform: scale(0.95);
}

.occupantDetailsCard {
    border-radius: 10px;
    background-color: white;
    overflow: hidden;
    border: 1px solid #dfe3ea;
    transition: transform 0.3s ease;
    display: flex;
    padding: 10px;
    margin-top: 8px;
    justify-content: space-between;
    align-items: center;
}

.occupant {
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.occupantInfo {
    margin-left: 15px;
    flex-grow: 1;
}

.occupantHeader {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.name {
    font-weight: 700;
    font-size: 1rem;
    margin: 0;
}

.contactLink {
    text-decoration: none;
    color: #19248a;
    transition: color 0.3s;
    margin-left: 10px;
}

.contactLink:hover {
    color: #0a0f0d;
}

.contactIcon {
    font-size: 1.2rem;
}

.deleteButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 10px;
    transition: transform 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deleteButton:hover {
    transform: scale(1.1);
}

.deleteIcon {
    font-size: 1.3rem;
    color: #d9534f;
}

.emptyText {
    width: 100%;
    margin: 0;
    text-align: center;
    font-size: 14px;
}

/* Keyframe animations */
@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
