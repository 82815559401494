/* TenantDetails.css */
.tenant-detail-avatar {
    width: 150px;
    height: 150px;
    border-radius: 75px;
}

@media (max-width: 767.98px) {
    .tenant-detail-avatar {
        width: 80px;
        height: 80px;
        border-radius: 40px;
    }
}
