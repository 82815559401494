/* PieChart.css */
.pie-chart {
    position: relative;
    width: 200px;
    height: 200px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.pie-chart-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    font-size: 18px;
}
